import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="M60.08 74.26L60.08 56.56L62.19 52.58L62.19 74.26L60.08 74.26ZM64.30 74.26L64.30 48.48L66.41 44.49L66.41 74.26L64.30 74.26ZM68.52 74.26L68.52 40.51L70.63 36.49L70.63 74.26L68.52 74.26ZM70.07 25.74L54.37 55.57L53.16 53.37L67.70 25.74L70.07 25.74ZM65.29 25.74L51.93 50.97L50.70 48.74L62.89 25.74L65.29 25.74ZM37.81 74.26L37.81 52.81L39.92 56.80L39.92 74.26L37.81 74.26ZM33.59 74.26L33.59 44.79L35.70 48.77L35.70 74.26L33.59 74.26ZM29.38 74.26L29.38 36.76L31.48 40.77L31.48 74.26L29.38 74.26ZM27.53 25.74L46.43 61.60L44.05 61.60L27.27 29.76L27.27 74.26L25.16 74.26L25.16 25.74L27.53 25.74ZM32.33 25.74L51.20 61.60L48.80 61.60L29.93 25.74L32.33 25.74ZM72.73 74.26L72.73 29.76L55.95 61.60L53.54 61.60L34.71 25.74L37.08 25.74L54.78 59.38L72.44 25.74L74.84 25.74L74.84 74.26L72.73 74.26Z"
          fill="currentColor"
        />
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
